import React, { useEffect, useMemo, useState } from "react";
import "./App.css";
import Table from "./Table";
import rankingCsv from "./ranking.csv";
import { readString } from "react-papaparse";
import {
  FaAngleDoubleDown,
  FaAngleDown,
  FaAngleDoubleUp,
  FaAngleUp,
  FaStar,
} from "react-icons/fa";

const Tags = ({ values }) => {
  return (
    <>
      {values.map((tag, idx) => {
        return (
          <span key={idx} className="badge">
            {tag}
          </span>
        );
      })}
    </>
  );
};

const Rating = ({ stars }) => {
  return (
    <div>
      <span className="dynamic-col">
        <span>
          {Array(5)
            .fill()
            .map((_, index) => (
              <FaStar key={index} color={index < parseInt(stars) ? "orange" : "lightgray"} />
            ))}
        </span>
        <span>({stars})</span>
      </span>
      <span class="mobile">
        {stars}
        <FaStar color="orange" />
      </span>
    </div>
  );
};

const renderDetails = ({ row }) => {
  return (
    <>
      <div>
        Website:{" "}
        <a
          href={row.original.website}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.original.website}
        </a>
      </div>
      <div>City: {row.original.city}</div>
      <div className="review">{row.original.review}</div>
    </>
  );
};

function App() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getData() {
      const parseConfig = {
        header: true,
        complete: (results) => {
          results.data.forEach((val, index) => {
            val.rank = index + 1;
            val.tags = val.tags.split("|").filter((tag) => tag);
          });
          setData(results.data);
        },
      };
      fetch(rankingCsv)
        .then((response) => response.text())
        .then((responseText) => readString(responseText, parseConfig));
    }
    getData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "They Escape",
        columns: [
          {
            Header: "Rank",
            accessor: "rank",
            enableGlobalFilter: false,
          },
          {
            Header: "Room Name",
            accessor: "name",
          },
          {
            Header: "Company Name",
            accessor: "company",
          },
          {
            Header: "Region",
            accessor: "region",
          },
          {
            Header: "Players",
            accessor: "players",
            enableGlobalFilter: false,
          },
          {
            Header: "Tags",
            accessor: "tags",
            Cell: ({ cell: { value } }) => <Tags values={value} />,
          },
          {
            Header: "Rating",
            accessor: "rating",
            enableGlobalFilter: false,
            Cell: ({ cell: { value } }) => <Rating stars={value} />,
          },
          {
            Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
              <>
                <span className="dynamic-col">Details </span>
                <span {...getToggleAllRowsExpandedProps()}>
                  {isAllRowsExpanded ? (
                    <FaAngleDoubleUp />
                  ) : (
                    <FaAngleDoubleDown />
                  )}
                </span>
              </>
            ),
            accessor: "details",
            enableGlobalFilter: false,
            Cell: ({ row }) => (
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? <FaAngleUp /> : <FaAngleDown />}
              </span>
            ),
          },
        ],
      },
    ],
    []
  );

  return (
    <div className="App">
      <Table columns={columns} data={data} renderDetails={renderDetails} />
      <div style={{ textAlign: "center" }}>
        <p style={{ fontSize: "12px" }}>This site was previously theroomrank.com (no longer available)</p>
      </div>
    </div>
  );
}

export default App;

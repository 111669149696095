import React, { useState } from "react";
import { useExpanded, useGlobalFilter, useTable } from "react-table";
import {
  FaAngleDown,
  FaAngleUp,
  FaInfoCircle,
  FaTimes,
} from "react-icons/fa";

function Table({ columns, data, renderDetails }) {
  const [filterInput, setFilterInput] = useState("");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useExpanded
  );

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setFilterInput(value);
  };

  const getColumnVisibilityClass = (col) => {
    const dynamicCols = ["rank", "players", "company"];
    return dynamicCols.includes(col) ? "dynamic-col" : "";
  };

  const About = ({ initialCollapsed = false }) => {
    const [collapsed, setCollapsed] = useState(initialCollapsed);

    const toggleCollapse = () => {
      setCollapsed(!collapsed);
    };

    return(
      <div className="about-section">
        <h3 onClick={toggleCollapse} style={{ cursor: "pointer" }}>
          About They Escape {collapsed ? <FaAngleDown /> : <FaAngleUp />}
        </h3>
        {!collapsed && (
          <p className="about-context">
            This stack ranking is a guide by enthusiasts to help people get a better understanding of what they can expect
            in a given escape room. The ranking has been meticulously discussed and curated after gaining the experience of 100+ quality
            escape rooms, and we hope that this direct comparison will allow you to get a sense for how good a room is in the context of reputable rooms.
            We generally favor rooms with unique and novel elements over standard rooms with good polish, since standard experiences aren't as interesting
            once you've gone through a hundred of them. Note that these rankings are competitive since we tend to only do rooms with good reputations,
            which means that a room with a low ranking isn't necessarily bad. A room's rating on the rubric and its review will likely be more
            informative than the rank itself.
          </p>
        )}
        <KoFiButton />
      </div>
    );
  }

  const KoFiButton = () => {
    const kofiUrl = 'https://ko-fi.com/M4M12G3W4';
    const kofiImage = 'https://storage.ko-fi.com/cdn/kofi1.png?v=3';
    const altText = 'Buy Me a Coffee at ko-fi.com';
    const imageStyle = {
      border: '0px',
      height: '36px',
    };
  
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
        <p style={{ marginRight: '10px' }}>Want to support this page?</p>
        <a href={kofiUrl} target="_blank" rel="noreferrer noopener">
          <img src={kofiImage} alt={altText} style={imageStyle} />
        </a>
      </div>
    );
  };

  const Info = ({ initialCollapsed = true }) => {
    const [collapsed, setCollapsed] = useState(initialCollapsed);

    const toggleCollapse = () => {
      setCollapsed(!collapsed);
    };

    return(
      <div className="info-section">
        <h4 onClick={toggleCollapse} style={{ cursor: "pointer" }}>
          Rubric and Tags Guide {collapsed ? <FaInfoCircle /> : <FaTimes />}
        </h4>
        {!collapsed && (
          <div className="info-container">
            <div className="guide-col">
              <h4>
                Rubric
              </h4>
              <ul className="guide-content">
                <li>
                  <strong>5 - Excellent</strong>: Excellent and memorable room where we came out of it impressed.
                  Enthusiasts should put this on their itinerary, likely the highlight of a trip.
                </li>
                <li>
                  <strong>4 - Good</strong>: Good room with a little something that makes the room distinctive.
                  We will happily recommend these rooms to travelling enthusiasts or locals.
                </li>
                <li>
                  <strong>3 - Decent</strong>: Decent room, but didn't do enough to distinguish itself compared
                  to a standard escape room. We generally still recommend the higher end of this rating as worth
                  going to for travelling enthusiasts and locals, it's just not an enthusiastic recommendation.
                  The lower end contains rooms that are still reasonable games, but you can take it or leave it.
                </li>
                <li>
                  <strong>2 - Flawed</strong>: Flawed room with something frustrating enough to make this worse
                  than a standard room, but not enough to ruin the experience. We're still upset about it, and do
                  not recommend these rooms.
                </li>
                <li>
                  <strong>1 - Bad</strong>: Bad room that is a waste of time. There was something about this room
                  that ruined the experience. They didn't even try.
                </li>
              </ul>
            </div>
            <div className="guide-col">
              <h4>
                Tags
              </h4>
              <ul className="guide-content">
                <li>
                  <strong>Puzzles</strong>: Has multiple puzzles that are better than standard escape room puzzles,
                  either through nuances that add a layer of interesting complexity or through incorporating novel technology.
                </li>
                <li>
                  <strong>Set</strong>: The physical environment feels realistic and is more interesting than what you would
                  encounter in day-to-day life. This generally includes having some combination of custom and well polished props,
                  immersive sound or special effects, or diverse environments within a single game.
                </li>
                <li>
                  <strong>Story</strong>: Has a memorable plot progression or significant amounts of lore throughout the room.
                  This generally incorporates the puzzles and your actions having purpose in the context of the story, character development,
                  or a unique concept for the world.
                </li>
                <li>
                  <strong>Gameplay</strong>: Has novel elements that significantly deviate from standard escape rooms.
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <About />
      <label className="input">
        <input
          className="input__field"
          type="text"
          value={filterInput}
          onChange={handleFilterChange}
          placeholder=" "
        />
        <span className="input__label">Search Rooms</span>
      </label>
      <h2 className="subtitle">*search rooms, companies, regions, or tags*</h2>
      <Info />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width },
                    className: getColumnVisibilityClass(column.id),
                  })}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          className:
                            "row-style " +
                            getColumnVisibilityClass(cell.column.id),
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderDetails({ row })}
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default Table;
